/* src/App.css or src/Dashboard.css */

.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr {
  transition: all 0.3s;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}
.chart {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 20px;
  margin-left: 25px;
}

.chart-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.class {
  flex: 1;
  font-weight: bold;
}

.subject-day {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.subject {
  font-size: 1.2em;
  font-weight: bold;
}

.day {
  font-size: 0.9em;
  color: #777;
}

.progress {
  flex: 17;
}

.progress-bar {
  background-color: rgb(63 88 98);
}

.card.text {
  font-size: 20px;
}
/* .card {
  width: 300px;
  height: 140px;
} */
body {
  background-color: #f3f6f9;
  font-family: Montserrat, sans-serif;
}
.s0 {
  opacity: 0.05;
  fill: var(--vz-secondary);
}
.Card-animate:hover {
  /* -webkit-transform: translateY(calc(-1.5rem / 5)); */
  transform: translateY(calc(-1.5rem / 5));
  /* -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12); */
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}
